import { useCallback, useContext, useMemo } from 'react';
import {
  CalendarAccounts,
  useUpdateCalendarAccountStatusMutation,
  useUpdateCalendarPreferenceMutation,
  useUpdateDefaultCalendarPreferenceMutation,
} from 'types/generated/client';
import { CurrentUserContext } from 'context/CurrentUserContext';
import { useViewer } from './useViewer';

interface UseCalendarAccountsReturn {
  accounts: CalendarAccounts[];
  loading: boolean;
  hasAccounts: boolean;
  getAccountByEmail: (email: string) => CalendarAccounts | undefined;
  disconnectAccount: (accountId: string) => Promise<void>;
  toggleCalendarPreference: (preferenceId: string, isEnabled: boolean) => Promise<void>;
  setDefaultCalendarToPush: (preferenceId: string) => Promise<void>;
  connectedAccounts: CalendarAccounts[];
}

export const useCalendarAccounts = (): UseCalendarAccountsReturn => {
  const { calendarAccounts, calendarAccountsLoading } = useContext(CurrentUserContext);

  const [updateAccountStatus] = useUpdateCalendarAccountStatusMutation();
  const [updatePreference] = useUpdateCalendarPreferenceMutation();
  const [updateDefaultCalendarPreference] = useUpdateDefaultCalendarPreferenceMutation();

  const viewer = useViewer();

  const connectedAccounts = useMemo(
    () => (calendarAccounts as CalendarAccounts[])?.filter((account) => account.connected) || [],
    [calendarAccounts],
  );

  const getAccountByEmail = useCallback(
    (email: string) =>
      calendarAccounts &&
      (calendarAccounts as CalendarAccounts[]).find((account) => account.email === email),
    [calendarAccounts],
  );

  const disconnectAccount = useCallback(
    async (accountId: string) => {
      try {
        await updateAccountStatus({
          variables: {
            id: accountId,
            connected: false,
          },
          refetchQueries: ['getCalendarAccounts'],
        });
      } catch (error) {
        console.error('Failed to disconnect account:', error);
        throw error;
      }
    },
    [updateAccountStatus],
  );

  const toggleCalendarPreference = useCallback(
    async (preferenceId: string, isEnabled: boolean) => {
      try {
        await updatePreference({
          variables: {
            id: preferenceId,
            isEnabled,
          },
          refetchQueries: ['getCalendarAccounts'],
        });
      } catch (error) {
        console.error('Failed to update calendar preference:', error);
        throw error;
      }
    },
    [updatePreference],
  );

  const setDefaultCalendarToPush = useCallback(
    async (preferenceId: string) => {
      try {
        await updateDefaultCalendarPreference({
          variables: {
            preferenceId,
            userId: viewer.userId,
          },
          refetchQueries: ['getCalendarAccounts'],
        });
      } catch (error) {
        console.error('Failed to set default calendar:', error);
        throw error;
      }
    },
    [updateDefaultCalendarPreference, viewer.userId],
  );

  return {
    accounts: (calendarAccounts as CalendarAccounts[]) || [],
    loading: calendarAccountsLoading,
    hasAccounts: connectedAccounts!.length > 0,
    getAccountByEmail,
    connectedAccounts,
    toggleCalendarPreference,
    disconnectAccount,
    setDefaultCalendarToPush,
  };
};
