import React, { useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { addMinutes, format, parseISO } from 'date-fns';
import { NEW_LESSON_PAGE, getLessonPageUrl } from 'constants/pages';
import { ImageSquareForLessonType, lessonShortName } from 'constants/sports';
import { MONTH_INDEX, MONTH_INDEX_SHORT } from 'constants/time';
import { LessonTypesEnum } from 'types/generated/client';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getProfileImageUrlOrPlaceholder } from 'utils/shared/user/getProfileImageUrlOrPlaceholder';
import { useCalendarEvents } from 'hooks/useCalendarEvents';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import AlertIcon from 'svg/AlertIcon';
import Button, { ButtonLink } from 'components/Button';
import Link from 'components/Link';
import Modal, { useModal } from 'components/modals/Modal';
import ModalCoachCancelComplete from 'components/modals/ModalCoachCancelComplete';
import ModalCoachCancelPrompt from 'components/modals/ModalCoachCancelPrompt';
import classNames from 'styles/utils/classNames';
import CalendarSwiper from './CalendarSwiper';
import { CalendarBlock, Props } from './props';
import { calendarLinks, checkTimeConflicts, generateCalendarUrl, getMeaningfulDate } from './utils';

const LessonCalendar: React.FC<Props> = ({
  lessons,
  isOwner,
  isTransparentHeader,
  showName = false,
  onCancelLesson,
  coachAvailability,
  isAnonymous = false,
  onSlotSelect,
}) => {
  const [swiperRef, setSwiperRef] = React.useState<any | null>(null);
  const { user } = useGetCurrentUser();
  const [activeCalendarBlock, setActiveCalendarBlock] = React.useState<null | CalendarBlock>(null);
  const [activeLessonId, setActiveLessonId] = React.useState('');
  const [month, setMonth] = React.useState<null | number>(null);
  const [year, setYear] = React.useState('');
  const { events, loading: eventsLoading, fetchEvents, cancelEvent } = useCalendarEvents();
  const [conflictMessage, setConflictMessage] = React.useState<string>('');

  const activeLesson = lessons.find((lesson) => lesson.id === activeLessonId);
  const price = activeLesson?.priceUnitAmount
    ? convertUnitPriceToFormattedPrice(activeLesson?.priceUnitAmount).priceDisplay
    : '';
  const participantCount = activeLesson?.participants?.length || 0;
  const participantLimit = activeLesson?.participantLimit || 0;

  const activeBlockDate = getMeaningfulDate(activeCalendarBlock);

  const activeOwnerProfile = activeLesson?.ownerProfile;
  const activeLessonImageUrl = getProfileImageUrlOrPlaceholder({
    path: activeOwnerProfile?.profileImagePath,
  });
  const fallbackImageUrl = ImageSquareForLessonType[activeLesson?.type || LessonTypesEnum.Custom];

  const bottomSheetModal = useModal();
  const coachCancelPromptModal = useModal();
  const coachCancelCompleteModal = useModal();
  const lessonDetailModal = useModal();

  const handleOpenCoachCancelPrompt = () => {
    lessonDetailModal.closeModal();
    coachCancelPromptModal.openModal();
  };

  const handleCloseLessonDetailModal = () => {
    lessonDetailModal.closeModal();
    setActiveLessonId('');
  };

  const handleCoachCancelPromptBack = () => {
    coachCancelPromptModal.closeModal();
    lessonDetailModal.openModal();
  };

  const handleCancelComplete = () => {
    coachCancelPromptModal.closeModal();
    coachCancelCompleteModal.openModal();
    cancelEvent(activeLessonId);
    onCancelLesson?.();
  };

  const renderCreateLessonButton = (slotTime: Date) => {
    return (
      <ButtonLink
        href={`${NEW_LESSON_PAGE}?datetime=${slotTime.getTime()}`}
        variant="primary"
        className="w-fit py-3"
        size="md"
      >
        Create lesson
      </ButtonLink>
    );
  };

  useEffect(() => {
    if (activeBlockDate && events.length > 0) {
      const currentTimeConflict = checkTimeConflicts(activeBlockDate, events);
      if (currentTimeConflict.conflictingEvent && currentTimeConflict.hasConflict) {
        const event = currentTimeConflict.conflictingEvent;

        if (event?.start!.dateTime && event?.end!.dateTime) {
          const startTime = format(parseISO(event.start.dateTime), 'h:mm a');
          const endTime = format(parseISO(event.end.dateTime), 'h:mm a');

          setConflictMessage(
            `This time conflicts with "${event.summary}" (${startTime} - ${endTime}) in ${event.calendarName} calendar`,
          );
        }
      } else {
        setConflictMessage('');
      }
    }
  }, [activeBlockDate, events]);

  useEffect(() => {
    activeLessonId && lessonDetailModal.openModal();
  }, [activeLessonId]);

  return (
    <>
      <div className="relative flex h-full w-full flex-auto flex-col overflow-hidden">
        <div
          className={classNames(
            'jusify-between flex w-full items-center bg-color-bg-lightmode-primary px-6 dark:bg-color-bg-darkmode-primary',
            isTransparentHeader
              ? 'bg-transparent'
              : 'bg-color-bg-lightmode-primary pb-4  dark:bg-color-bg-darkmode-primary md:py-4',
            isOwner && 'pt-16 md:pt-4',
          )}
        >
          {month !== null ? (
            <div className="flex w-full shrink-0 grow items-center justify-between lg:justify-center lg:space-x-8">
              <button
                onClick={() => {
                  swiperRef?.slidePrev();
                }}
              >
                <ChevronLeftIcon className="h-7 w-7 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary" />
              </button>
              <div className="font-semibold leading-6 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                <span className="lg:hidden">{MONTH_INDEX_SHORT[month]}</span>
                <span className="hidden lg:inline">{MONTH_INDEX[month]}</span> {year}
              </div>

              <button
                onClick={() => {
                  swiperRef?.slideNext();
                }}
              >
                <ChevronRightIcon className="h-7 w-7 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary" />
              </button>
            </div>
          ) : (
            <div className="h-7 w-full">&nbsp;</div>
          )}
        </div>

        <CalendarSwiper
          setSwiperRef={setSwiperRef}
          setMonth={setMonth}
          setYear={setYear}
          lessons={lessons}
          isOwner={isOwner}
          loading={eventsLoading}
          isTransparentHeader={isTransparentHeader}
          fetchExternalCalendarEvents={fetchEvents}
          externalEvents={events}
          toggleBottomSheet={() => {
            if (!user) {
              return;
            }
            bottomSheetModal.toggleModal();
          }}
          activeCalendarBlock={activeCalendarBlock}
          setActiveCalendarBlock={setActiveCalendarBlock}
          onButtonClick={(currentCalendarBlock) =>
            onSlotSelect?.(getMeaningfulDate(currentCalendarBlock), currentCalendarBlock)
          }
          activeLessonId={activeLessonId}
          setActiveLessonId={setActiveLessonId}
          showName={showName}
          isAnonymous={isAnonymous}
          coachAvailability={coachAvailability}
        />
      </div>
      <Modal
        positionBottomDesktop
        isOpen={bottomSheetModal.isOpen && !!activeBlockDate}
        handleClose={bottomSheetModal.closeModal}
        swipeProps={{
          onSwipedDown: () => bottomSheetModal.closeModal(),
          delta: 50,
          trackMouse: true,
          trackTouch: true,
        }}
      >
        <div className="p-6">
          <div className="flex items-start justify-between">
            <Dialog.Title
              as="h3"
              tabIndex={0}
              className="text-xl font-bold leading-7 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary"
            >
              {format(activeBlockDate || new Date(), 'EEEE, MMM d')}
            </Dialog.Title>
            <button
              type="button"
              className="rounded-md bg-color-bg-lightmode-primary text-color-text-lightmode-primary focus:outline-none focus:ring-2 focus:ring-color-checkbox-active focus:ring-offset-2 dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary"
              onClick={() => bottomSheetModal.closeModal()}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-4">
            <p className="text-lg text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              No lessons have been scheduled yet at this date and time.
            </p>
          </div>
          {conflictMessage && (
            <div className="flex items-center gap-2">
              <AlertIcon className="shrink-0 text-[#cc3300]" />
              <p className="mt-2 text-xs text-red-500">{conflictMessage}</p>
            </div>
          )}
          <div className="mt-4 space-y-4">
            <div className="flex items-center justify-between rounded-md border border-color-border-input-lightmode px-4 py-2.5 dark:border-color-border-input-darkmode">
              <div className="text-lg font-medium text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                {format(activeBlockDate || new Date(), 'p')}
              </div>
              {renderCreateLessonButton(activeBlockDate || new Date())}
            </div>
            <div className="flex items-center justify-between rounded-md border border-color-border-input-lightmode px-4 py-2.5 dark:border-color-border-input-darkmode">
              <div className="text-lg font-medium text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                {format(addMinutes(activeBlockDate || new Date(), 30), 'p')}
              </div>
              {renderCreateLessonButton(addMinutes(activeBlockDate || new Date(), 30))}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        positionBottomDesktop
        isOpen={lessonDetailModal.isOpen}
        handleClose={handleCloseLessonDetailModal}
        swipeProps={{
          onSwipedDown: handleCloseLessonDetailModal,
          delta: 50,
          trackMouse: true,
          trackTouch: true,
        }}
      >
        <div className="p-6">
          <div>
            <div className="flex items-start justify-between">
              <Dialog.Title
                as="h3"
                tabIndex={0}
                className="text-xl font-bold leading-7 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary"
              >
                {!!activeLesson?.startDateTime
                  ? format(new Date(activeLesson.startDateTime), 'EEEE, MMM d')
                  : ' '}
                &nbsp;
              </Dialog.Title>
              <button
                type="button"
                className="rounded-md bg-color-bg-lightmode-primary text-color-text-lightmode-primary focus:outline-none focus:ring-2 focus:ring-color-checkbox-active focus:ring-offset-2 dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary"
                onClick={handleCloseLessonDetailModal}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-4 flex h-full grow gap-2">
              <div className="flex flex-col">
                {!!activeLessonImageUrl && (
                  <img
                    src={activeLessonImageUrl}
                    className="h-24 w-24 rounded-full object-cover object-top"
                    alt={activeLesson?.title}
                  />
                )}
                {!activeLessonImageUrl && fallbackImageUrl && (
                  <img
                    src={fallbackImageUrl}
                    className="min-h-card-image min-w-card-image rounded-full object-cover object-center"
                    alt={activeLesson?.title}
                  />
                )}
              </div>
              {!!activeLesson && (
                <div className="flex h-full grow flex-col justify-between">
                  <div className="flex flex-col gap-1">
                    <div
                      className={classNames(
                        'flex flex-1 items-center justify-between   text-color-text-lightmode-primary dark:text-color-text-darkmode-primary',
                      )}
                    >
                      <span
                        className={classNames(
                          'mr-2 flex h-5 items-center rounded-2xl px-2 text-xs font-medium',
                          activeLesson.type === LessonTypesEnum.Individual &&
                            'bg-color-lesson-individual text-color-brand-primary',
                          activeLesson.type !== LessonTypesEnum.Individual &&
                            'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary',
                          activeLesson.type === LessonTypesEnum.Cardio && 'bg-color-lesson-cardio',
                          activeLesson.type === LessonTypesEnum.Clinic && 'bg-color-lesson-clinic',
                          activeLesson.type === LessonTypesEnum.Camp && 'bg-color-lesson-camp',
                          activeLesson.type === LessonTypesEnum.Custom && 'bg-color-lesson-other',
                        )}
                      >
                        {lessonShortName[activeLesson.type]}
                      </span>
                      <span className="typography-product-heading leading-3">{price}</span>
                    </div>
                    <span className="text-sm font-medium text-brand-gray-600 dark:text-color-text-darkmode-secondary">
                      {format(new Date(activeLesson.startDateTime), 'p')} -{' '}
                      {format(new Date(activeLesson.endDateTime), 'p')}
                    </span>
                    {!!activeLesson?.title && (
                      <span className="typography-product-subheading">{activeLesson.title}</span>
                    )}

                    <div className="flex grow flex-col justify-end">
                      <div className="flex items-center justify-between  gap-1">
                        <span className="typography-product-chips-filters text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                          With: {activeOwnerProfile?.fullName}
                        </span>
                        {}

                        <div className="typography-product-chips-filters flex">
                          <span className="text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                            {participantCount || 0}
                          </span>
                          <span className="text-brand-gray-400">/{participantLimit} filled</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr className=" my-8 border-t border-solid border-color-border-input-lightmode dark:border-color-border-input-darkmode" />
            <span className="typography-product-subheading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              Add to Calendar
            </span>

            {activeLesson && (
              <div className="flex  justify-around gap-2 py-5">
                {calendarLinks.map(({ Icon, type, className }, index) => (
                  <Link
                    href={generateCalendarUrl(type, activeLesson)}
                    target="_blank"
                    className="cursor-pointer"
                    key={index}
                  >
                    <Icon className={className} />
                  </Link>
                ))}
              </div>
            )}

            <div className="mt-4 flex gap-2 border-t border-solid border-color-border-input-lightmode pt-6 dark:border-color-border-input-darkmode">
              <Button
                variant="inverted"
                onClick={handleOpenCoachCancelPrompt}
                className="w-1/2"
                size="lg"
              >
                Cancel lesson
              </Button>
              <ButtonLink
                variant="primary"
                href={getLessonPageUrl(activeLesson?.id)}
                className="w-1/2"
                size="lg"
              >
                View details
              </ButtonLink>
            </div>
          </div>
        </div>
      </Modal>
      <ModalCoachCancelPrompt
        isOpen={coachCancelPromptModal.isOpen}
        setIsOpen={coachCancelPromptModal.setIsOpen}
        handleBack={handleCoachCancelPromptBack}
        handleCancelComplete={handleCancelComplete}
        lessonId={activeLessonId}
      />
      <ModalCoachCancelComplete
        isOpen={coachCancelCompleteModal.isOpen}
        setIsOpen={coachCancelCompleteModal.setIsOpen}
        backButtonLabel={'Close'}
        handleButtonClick={coachCancelCompleteModal.closeModal}
      />
    </>
  );
};

export default LessonCalendar;
