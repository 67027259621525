// utils/calendar/checkConflicts.ts
import { addMinutes, isWithinInterval, parseISO } from 'date-fns';
import { calendar_v3 } from 'googleapis';
import AppleLogo from 'svg/AppleLogo';
import GoogleCalendarLogo from 'svg/GoogleCalendarLogo';
import OutlookLogo from 'svg/OutlookLogo';
import { CalendarBlock, CalendarLinkType, GoogleEvent, Lesson } from './props';

export const generateCalendarUrl = (type: CalendarLinkType, lesson: Lesson) => {
  const start = new Date(lesson.startDateTime).toISOString().replace(/-|:|\.\d+/g, '');
  const end = new Date(lesson.endDateTime).toISOString().replace(/-|:|\.\d+/g, '');
  const startOutlook = encodeURIComponent(new Date(lesson.startDateTime).toISOString());
  const endOutlook = encodeURIComponent(new Date(lesson.endDateTime).toISOString());
  const title = encodeURIComponent(lesson.title);
  const description = encodeURIComponent(lesson.description);
  let location = null;
  if (lesson?.userCustomCourt?.fullAddress || lesson?.venues?.addressString) {
    location = encodeURIComponent(
      lesson?.userCustomCourt?.fullAddress || lesson?.venues?.addressString || '',
    );
  }

  switch (type) {
    case CalendarLinkType.GOOGLE:
      return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${start}/${end}&details=${description}&location=${location}`;
    case CalendarLinkType.APPLE:
      const icsContent = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTART:${start}\nDTEND:${end}\nSUMMARY:${title}\nDESCRIPTION:${description}\nLOCATION:${location}\nEND:VEVENT\nEND:VCALENDAR`;
      return `data:text/calendar;charset=utf8,${encodeURIComponent(icsContent)}`;
    case CalendarLinkType.OUTLOOK:
      return `https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=${description}&enddt=${endOutlook}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startOutlook}&subject=${title}`;
    default:
      return '#';
  }
};

export const calendarLinks = [
  {
    Icon: AppleLogo,
    type: CalendarLinkType.APPLE,
    className: 'h-7 w-7 dark:[&>g]:fill-color-button-darkmode',
  },
  {
    Icon: GoogleCalendarLogo,
    type: CalendarLinkType.GOOGLE,
    className: 'h-7 w-7',
  },
  {
    Icon: OutlookLogo,
    type: CalendarLinkType.OUTLOOK,
    className: 'h-7 w-7',
  },
];

export const getMeaningfulDate = (calendarBlock?: CalendarBlock | null): Date | null => {
  if (!calendarBlock || !calendarBlock.date || !calendarBlock.time) {
    return null; // Return null if calendar block is invalid
  }

  // Ensure the time is formatted correctly, padding single-digit hours
  const formattedTime = `${calendarBlock.time}`.padStart(2, '0');
  const dateTimeString = `${calendarBlock.date}T${formattedTime}:00`;

  const meaningfulDate = new Date(dateTimeString);

  // Check if the created Date object is valid
  if (isNaN(meaningfulDate.getTime())) {
    return null; // Invalid date case
  }

  return meaningfulDate;
};

export const checkTimeConflicts = (date: Date, events: GoogleEvent[]) => {
  const slotStart = date;
  const slotEnd = addMinutes(date, 30);

  for (const event of events) {
    if (
      !event.start?.dateTime ||
      !event.end?.dateTime ||
      event.extendedProperties?.private?.source === 'bounce' // Skip Bounce events
    )
      continue;

    const eventStart = parseISO(event.start.dateTime);
    const eventEnd = parseISO(event.end.dateTime);

    if (
      isWithinInterval(slotStart, { start: eventStart, end: eventEnd }) ||
      isWithinInterval(slotEnd, { start: eventStart, end: eventEnd }) ||
      (slotStart <= eventStart && slotEnd >= eventEnd)
    ) {
      return {
        hasConflict: true,
        conflictingEvent: event,
      };
    }
  }

  return { hasConflict: false };
};
