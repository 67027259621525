import { useCallback, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { calendar_v3 } from 'googleapis';
import { DELETE_EVENT_API, DeleteEventPayload } from 'constants/payloads/google/deleteEvent';
import { UPDATE_EVENT_API, UpdateEventPayload } from 'constants/payloads/google/updateEvent';
import { useApiGateway } from 'hooks/useApi';
import { useCalendarAccounts } from './useCalendarAccounts';

type GoogleEvent = calendar_v3.Schema$Event;
export type CalendarEvent = GoogleEvent & { calendarName: string };

export interface FetchEventsResponse {
  events: CalendarEvent[];
}

export const useCalendarEvents = () => {
  const {
    get: fetchEvents,
    data,
    isLoading,
    error,
  } = useApiGateway<never, FetchEventsResponse>('/v1/google/events');

  const { put: updateExternalEvent, isLoading: updateLoading } =
    useApiGateway<UpdateEventPayload>(UPDATE_EVENT_API);

  const { delete: cancelExternalEvent, isLoading: cancelLoading } =
    useApiGateway<DeleteEventPayload>(DELETE_EVENT_API);

  const currentRange = useRef<{ startDate: Date; endDate: Date } | null>(null);

  const skippedFirstFetch = useRef(false);

  const { accounts } = useCalendarAccounts();

  const getEvents = useCallback(async (startDate: Date, endDate: Date) => {
    currentRange.current = { startDate, endDate };
    const searchParams = new URLSearchParams({
      startDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      endDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    });

    return fetchEvents({
      endpoint: `?${searchParams.toString()}`,
    });
  }, []);

  const updateEvent = useCallback(
    async (payload: UpdateEventPayload) => {
      try {
        const result = await updateExternalEvent({ payload });

        if (currentRange.current) {
          await getEvents(currentRange.current.startDate, currentRange.current.endDate);
        }

        return result;
      } catch (error) {
        console.error('Failed to update external event:', error);
        throw error;
      }
    },
    [getEvents],
  );

  const cancelEvent = useCallback(
    async (lessonId: string) => {
      try {
        const result = await cancelExternalEvent({
          payload: { lessonId },
        });

        if (currentRange.current) {
          await getEvents(currentRange.current.startDate, currentRange.current.endDate);
        }

        return result;
      } catch (error) {
        console.error('Failed to cancel external event:', error);
        throw error;
      }
    },
    [getEvents],
  );

  useEffect(() => {
    if (accounts.length && !skippedFirstFetch.current) {
      skippedFirstFetch.current = true;
      return;
    }
    if (currentRange.current && accounts.length) {
      getEvents(currentRange.current.startDate, currentRange.current.endDate);
    }
  }, [accounts, getEvents]);

  return {
    events: data?.events || [],
    loading: isLoading || updateLoading || cancelLoading,
    error,
    fetchEvents: getEvents,
    updateEvent,
    cancelEvent,
  };
};
